import * as React from "react";
import {FC, useEffect, useState} from "react";
// @ts-ignore
import * as style from './Basket.module.scss';
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faTimes} from "@fortawesome/pro-light-svg-icons";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../services/ApiService";
import {setBasketAmount, setBasketID} from '../../stores/basket/basketSlice';
import BasketLoader from "../ContentLoaders/BasketLoader/BasketLoader";
import {toast} from "react-toastify";
import {Link, navigate} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

const mapState = ({member, basket, auth}) => ({
    member,
    basket,
    auth
})

const mapDispatch = {
    setBasketAmount,
    setBasketID,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type BasketProps = PropsFromRedux & {
    setBasketVisibility(bool: boolean): void,
    visibility: boolean
}

const Basket: FC<BasketProps> = (props) => {
    const [basketItems, setBasketItems] = useState([]);
    const [basketProducts, setBasketProducts] = useState([]);
    const [totalCost, setTotalCost] = useState('');
    const [loading, setLoading] = useState(true);
    const [loaderAmount, setLoaderAmount] = useState(5);
    const [shippingCost, setShippingCost] = useState('');
    const [hasReachedMinAmount, setHasReachedMinAmount] = useState(false);
    const [minAmount, setMinAmount] = useState('');
    const [vendors, setVendors] = useState([]);
    const [gtagSent, setGtagSent] = useState(false);

    useEffect(() => {
        if (props.visibility) {
            fetchBasket();
            Client.Raw.get('Basket/' + props.basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                setHasReachedMinAmount(res.data.HasReached);
                setMinAmount(res.data.MinAmount);
            }).catch(handleApiError).then(fetchVendors)
        }
    }, [props.visibility])

    useEffect(() => {
        fetchVendors();
    }, [basketItems])

    function fetchVendors() {
        let vendorList = '?filter[ID:not]=-1';

        basketItems.map((item) => {
            if (item.Product.VendorID !== null) {
                vendorList += '&filter[ID][]=' + item.Product.VendorID;
            }
        })

        if (basketItems.length > 0) {
            Client.Raw.get('Vendor/getActiveVendorsMinimalized' + vendorList).then((res) => {
                setVendors(res.data);
                setTimeout(() => {
                    setLoading(false)
                }, 300)
            }).catch(handleApiError);

            /*Client.Raw.get(vendorList).then((res) => {
                setVendors(res.data);
                setTimeout(() => {
                    setLoading(false)
                }, 300)
            })*/
        }
    }


    function fetchBasket() {
        setLoading(true);
        if (props.auth.Key) {
            Client.Basket.fetchAuthorized(props.auth.Key, props.basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    let dummy = [];
                    res.data.List.forEach(list => {
                        dummy.push(...list.Items);
                    })
                    setBasketItems(dummy);
                    setTotalCost(res.data.SubTotalNice);
                    props.setBasketAmount(res.data.TotalItems)

                    setTimeout(() => {
                        setLoading(false)
                    }, 300)
                } else {
                    setTimeout(() => {
                        setLoading(false)
                    }, 300);
                }
                Client.Raw.get('Basket/' + props.basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                    setHasReachedMinAmount(res.data.HasReached);
                }).catch(handleApiError)
            }).catch(handleApiError);

            Client.Basket.fetchAuthorizedForGtag(props.auth.Key, props.basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    let dummy2 = [];
                    res.data.List.forEach(list => {
                        let p = list.Items[0];
                        dummy2.push(p);
                    })
                    setBasketProducts(dummy2);
                } else {
                }
                setLoading(false)
            }).catch(handleApiError);
        } else {
            Client.Basket.fetch(props.basket.BasketID ? props.basket.BasketID : '').then((res) => {
                setTotalCost(res.data.SubTotalNice);
                props.setBasketAmount(res.data.TotalItems);
                props.setBasketID(res.data.ID);
                setTimeout(() => {
                    setLoading(false)
                }, 300)
                if (res.data.List.length) {
                    let dummy = [];
                    res.data.List.forEach(list => {
                        dummy.push(...list.Items);
                    })
                    setBasketItems(dummy);
                    Client.Raw.get('Basket/' + props.basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                        setHasReachedMinAmount(res.data.HasReached);
                    }).catch(handleApiError)
                }
                Client.Raw.get('Basket/' + props.basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                    setHasReachedMinAmount(res.data.HasReached);
                }).catch(handleApiError)
            })

            Client.Basket.fetchAuthorizedForGtag(props.auth.Key, props.basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    let dummy2 = [];
                    res.data.List.forEach(list => {
                        let p = list.Items[0];
                        dummy2.push(p);
                    })
                    setBasketProducts(dummy2);
                } else {
                }
                setLoading(false)
            }).catch(handleApiError);
        }
    }

    function deleteItem(itemID: number) {
        setLoading(true);
        Client.Auth.authGetNoID(props.auth.Key, 'Product/' + itemID + '/forGtag').then((res) => {
            let deleteItem = {
                'event': 'remove_from_cart',
                'data': {
                    'currency': 'EUR',
                    'value': res.data.Price * props.basket.BasketAmount,
                    'item': [{
                        'item_id': res.data.SKU,
                        'item_name': res.data.Title,
                        'quantity' : props.basket.BasketAmount,
                    }]
                }
            };

            //@ts-ignore
            if (window.dataLayer) {
                //@ts-ignore
                window.dataLayer.push(deleteItem);
            }
            console.log("set basket gtag stuff :)")
            console.log(window.dataLayer)
        })

        Client.Basket.deleteItem(props.basket.BasketID, itemID).then(() => {
            Client.Basket.fetchAuthorized(props.auth.Key, props.basket.BasketID).then((res) => {

                if (res.data.List.length) {
                    let dummy = [];
                    res.data.List.forEach(list => {
                        dummy.push(...list.Items);
                    })
                    setBasketItems(dummy);
                } else {
                    setBasketItems([])
                }

                toast.success('Produkt erfolgreich entfernt.');
                props.setBasketAmount(res.data.TotalItems)
                setTotalCost(res.data.SubTotalNice);
                Client.Raw.get('Basket/' + props.basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                    setHasReachedMinAmount(res.data.HasReached);
                }).catch(handleApiError)
            }).catch(handleApiError)

            Client.Basket.fetchAuthorizedForGtag(props.auth.Key, props.basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    let dummy2 = [];
                    res.data.List.forEach(list => {
                        let p = list.Items[0];
                        dummy2.push(p);
                    })
                    setBasketProducts(dummy2);
                } else {
                    setBasketProducts([])
                }
                setLoading(false)
            }).catch(handleApiError);
        }).catch(handleApiError).then(fetchVendors);

    }

    function goToCheckout() {
        document.body.style.overflow = "visible"
        navigate('/checkout');

    }

    function newQuantity(event, id, prodID) {
        setLoading(true)
        Client.Basket.modifyItem(props.basket.BasketID, id, prodID, event.target.value).then((res) => {
            Client.Basket.fetchAuthorized(props.auth.Key, props.basket.BasketID).then((res) => {
                let dummy = [];
                res.data.List.forEach(list => {
                    dummy.push(...list.Items);
                })
                setBasketItems(dummy);
                setTotalCost(res.data.SubTotalNice);
                props.setBasketAmount(res.data.TotalItems)
                Client.Raw.get('Basket/' + props.basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                    setHasReachedMinAmount(res.data.HasReached);
                }).catch(handleApiError)
            }).catch(handleApiError);

            Client.Basket.fetchAuthorizedForGtag(auth.Key, basket.BasketID).then((res) => {
                if (res.data.List.length) {
                    let dummy2 = [];
                    res.data.List.forEach(list => {
                        let p = list.Items[0];
                        dummy2.push(p);
                    })
                    setBasketProducts(dummy2);
                } else {
                }
                setLoading(false)
            }).catch(handleApiError);
        }).catch(handleApiError).then(fetchVendors)
    }

    function toggleBasket() {
        props.setBasketVisibility(false);
        document.body.style.overflow = "visible"
    }

    useEffect(() => {
        if (basketProducts.length > 0 && totalCost && !gtagSent) {
            let viewItem = {
                'event': 'view_cart',
                'data': {
                    'currency': 'EUR',
                    'value': totalCost,
                    'items': basketProducts,
                }
            };

            //@ts-ignore
            if (window.dataLayer) {
                //@ts-ignore
                window.dataLayer.push(viewItem);
            }
            console.log("set basket gtag stuff HERE :)")
            console.log(window.dataLayer)

            setGtagSent(true);
        }
    }, [basketProducts, totalCost])

    return (
        <div className={style.basketContainer}>
            <div className={style.basketInner}>
                <div className={style.basketHeading}>
                    <Col xs={12}>
                        <h4 className={style.basketHeadline + " text-center pt-2"}> Warenkorb </h4>

                        <FontAwesomeIcon icon={faTimes} className={style.closeIcon}
                                         onClick={toggleBasket}/>
                    </Col>
                </div>
                <div className={style.basketItems}>
                    {loading ?
                        <>
                            <BasketLoader amount={5}/>
                        </> :

                        <div>
                            {basketItems.map((basketItem, index) => {
                                return (
                                    <>
                                        <div className={style.basketItem} key={index}>
                                            <Col xs={3}>
                                                <Link
                                                    to={`/${(basketItem.Product.CategoryURL).toLowerCase()}/${(basketItem.Product.URLTitle).toLowerCase()}`}>
                                                    <img src={basketItem.Product.PreviewImage}
                                                         className={style.basketImage}/>
                                                </Link>
                                            </Col>
                                            <Col xs={8} className={style.itemNameHolder}>
                                                <Link
                                                    to={`/${(basketItem.Product.CategoryURL).toLowerCase()}/${(basketItem.Product.URLTitle).toLowerCase()}`}
                                                    className={style.noLinkStyling}>
                                                    {basketItem.Product.Title}   </Link><br/>
                                                <Form.Group className={style.selectionHolder}>
                                                    <Form.Control as={"select"} defaultValue={basketItem.Quantity}
                                                                  className={style.selection}
                                                                  onChange={(event) => newQuantity(event, basketItem.ID, basketItem.ProductID)}>
                                                        {Array.from(Array((basketItem.Product.OnStock + 1) > 20 ? 21 : (basketItem.Product.OnStock + 1)).keys()).map((option, index) => {
                                                            return (
                                                                <>
                                                                    {option !== 0 &&
                                                                        <option value={option}
                                                                                key={index}> {option}</option>}
                                                                </>
                                                            )
                                                        })}
                                                    </Form.Control>
                                                    x {basketItem.Product.NicePrice}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={1} onClick={() => deleteItem(basketItem.ProductID)}>
                                                <FontAwesomeIcon icon={faTimes} className={style.removeItem}/>
                                            </Col>
                                        </div>
                                        <Col xs={12} className={style.textRight}>
                                            <span>
                                            {vendors.map((vendor) => {
                                                return (vendor.ID === basketItem.Product.VendorID ?
                                                    <a className={style.vendorTitle}
                                                       href={`/haendleruebersicht/${vendor.URLSegment}`}>{vendor.Title}</a> : null)
                                            })}
                                                {/**!hasReachedMinAmount ? minAmount : null**/}
                                            </span>
                                            {loading ? <span>Händlername wird geladen</span> : null}
                                        </Col>
                                        <br/>
                                    </>
                                )
                            })}
                            {!hasReachedMinAmount ?
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={1} className={"-dflex align-items-center"}>
                                            <StaticImage src={"../../images/fairpackt.png"} className={style.fairIcon}
                                                         alt={""}/>
                                        </Col>
                                        <Col xs={11} className={style.fairContainer}>
                                            <b>Fairliebt. Fairpackt. Fairsendet.</b> Für einen ökologisch und logistisch
                                            sinnvollen Einkauf, musst du einen Mindestbestellwert von € 25,- pro Händler
                                            erreichen. Füge weitere Artikel von dem Händler hinzu, bei dem du diesen
                                            Betrag noch nicht erreicht hast.
                                        </Col>
                                    </Row>
                                </Col> : null}
                        </div>
                    }
                </div>
                <div className={style.basketFooter}>
                    <div className={style.basketPrice}>
                        <h4> Gesamtsumme: {totalCost ? totalCost : ' €0'}</h4>
                    </div>
                    <div className={style.basketButtons}>
                        <Button disabled={!basketItems.length || !hasReachedMinAmount}
                                className={style.basketButton + ' ' + style.orderButton}
                                onClick={goToCheckout}>
                        <span className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={faCreditCard} className={style.basketButtonIcon}/>
                        <p className="m-0 p-0">Zur Bestellung</p>
                        </span>
                        </Button>
                        {!hasReachedMinAmount ?
                            <div className={style.error}> Du hast den Mindestbestellwert von {minAmount} pro Händler
                                noch nicht
                                erreicht! </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connector(Basket);
